const pdpDefaultDocs = [
  {
    documentTemplateId: 'contract',
    documentKey: 'consumer_information.pdf',
    title: 'contract.consumer_information.pdf.title',
  },
  {
    documentTemplateId: 'risks',
    documentKey: 'risks.pdf',
    title: 'risks.risks.pdf.title',
  },
  {
    documentTemplateId: 'key_information_document',
    documentKey: 'key_information_document.pdf',
    title: 'key.information.document.title',
  },
  {
    documentTemplateId: 'emission_terms',
    documentKey: 'emission_terms.pdf',
    title: 'emission.terms.emission_terms.pdf.title',
  },
];

export default pdpDefaultDocs;
